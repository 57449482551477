<template>
  <div>
    <v-text-field
      ref="inputField"
      v-bind="$attrs"
      :label="$translation.t('Email')"
      outlined
      v-model="model"
      :rules="[rules.required, rules.email]"
      hide-details="auto"
      :readonly="disabled"
      :class="{ 'input-disabled': disabled }"
    >
    </v-text-field>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";
import projectInputFields from "@/utils/mixins/projectInputFields";

export default {
  name: "EmailField",
  props: {
    value: String,
    global: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [projectInputFields],
  data: () => ({
    rules,
    model: "",
  }),
  created() {
    if (this.global) this.model = this.email ?? "";
  },
  mounted() {
    this.model = this.value || this.model;
  },
  computed: {
    email() {
      return this.projectInformationValue("email");
    },
  },
  watch: {
    model(newEmail) {
      this.$emit("input", newEmail);
    },
    value(newValue) {
      this.model = newValue;
    },
    email(newValue) {
      if (this.global) this.model = newValue;
    },
  },
};
</script>
